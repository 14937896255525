<template>
  <div>
    <div
      class="row"
      v-if="form.not_approved_items && form.not_approved_items.length"
    >
      <div class="mt-3 divider"></div>
      <div class="col-md-12">
        <h4>
          <b>Solicitação de aprovação</b>
        </h4>
      </div>

      <div class="col-12">
        <table class="table table-striped">
          <thead
            class="font-weight-bold text-white"
            style="    background: #333;"
          >
            <tr>
              <td>Foto</td>
              <td style="width: 20rem">Material</td>
              <td>Unidade</td>
              <td class="text-center" style="width: 10rem;">
                Certificado
              </td>
              <td style="width: 15rem">Cidade</td>
              <td class="text-center">Licença</td>
              <td class="text-center">Vencimento</td>
              <td class="text-center">Observações</td>
              <td class="text-center">Aprovar coleta</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in form.not_approved_items" :key="item.id">
              <td>
                <div class="avatar-large-content">
                  <img :src="item.item.photo_url" />
                </div>
              </td>
              <td>{{ item.item.name }}</td>
              <td>{{ item.unity.unity }}</td>
              <td style="text-align: center;">{{ item.license_file.startsWith('http') ? 'Sim' : 'Não' }}</td>
              <td>{{ item.name_city }}/{{ item.initials }}</td>
              <td class="text-center">
                <div v-if="item.license_file.startsWith('http')">
                  <a :href="`${item.destination_certification}`" target="_blank">
                    <i class="fas fa-eye"></i>
                  </a>
                </div>
                <div v-else>
                    -
                </div>
              </td>
              <td class="text-center">
                {{ formatDate(item.destination_certification_expiration) || '-' }}
              </td>
              <td class="text-center">
                {{ item.destination_certification_observation ? item.destination_certification_observation.trim() ?  item.destination_certification_observation : '-'  : '-'}}
              </td>
              <td class="text-center">
                <a @click="approveMaterial(item, 1)" class="text-success" style="margin: 0 20px;">
                    <i class="fas fa-check"></i>
                  </a>
                  <a @click="approveMaterial(item, 0)" class="text-danger">
                    <i class="fa fa-times"></i>
                  </a>
              </td>
              <!-- <License
                :allCities="true"
                :item="item"
                @update="$emit('update')"
                itemType="action"
              /> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" v-if="form.approved_items && form.approved_items.length">
      <div class="mt-3 divider"></div>
      <div class="col-md-12">
        <h4>
          <b>Materiais Aprovados</b>
        </h4>
      </div>

      <div class="col-12">
        <table class="table table-striped">
          <thead class="font-weight-bold bg-primary text-white">
            <tr>
              <td>Foto</td>
              <td style="width: 10rem;">Material</td>
              <td>Unidade</td>
              <td class="text-center" style="width: 10rem;">
                Certificado
              </td>
              <td>Cidade</td>
              <td class="text-center">Licença</td>
              <td class="text-center">Vencimento</td>
              <td class="text-center" style="width: 15rem;">Cancelar coleta</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in form.approved_items" :key="item.id">
              <td>
                <div class="avatar-large-content">
                  <img :src="item.item.photo_url" />
                </div>
              </td>
              <td>{{ item.item.name }}</td>
              <td>{{ item.unity.unity }}</td>
              <td style="text-align: center;">{{ item.license_file.startsWith('http') ? 'Sim' : 'Não' }}</td>
              <td>
                {{ `${item.city.name}/${item.city.province}` }}
              </td>
              <td class="text-center">
                <div v-if="item.license_file.startsWith('http')">
                  <a :href="`${item.destination_certification}`" target="_blank">
                    <i class="fas fa-eye"></i>
                  </a>
                </div>
                <div v-else>
                    -
                </div>
              </td>
              <td class="text-center">
                {{ item.expiration_date || '-' }}
              </td>
              <td class="text-center">
                <a @click="approveMaterial(item, 0)" class="text-danger">
                  <i class="fa fa-times"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'
export default {
  props: {
    form: Object
  },
  data: () => ({
    getCitiesToApproveLoading: false,
    saveApprovedCitiesLoading: false,
    modal: false,
    citiesToAprove: [],
    currentAproveMaterial: ''
  }),
  methods: {
    formatDate (time) {
      if (time) {
        const str = time.split('-')

        return str[2] + '/' + str[1] + '/' + str[0]
      } else {
        return '-'
      }
    },
    async getCitiesToApprove ({ item, item_id, material }) {
      item.getCitiesToApproveLoading = true
      const url = `admin/users/get-cities-to-approve/${this.form.id}/${item_id}`
      this.citiesToAprove = (await this.$http.get(url)).data
      this.currentAproveMaterial = material
      this.modal = true
      item.getCitiesToApproveLoading = false
    },
    async saveApprovedCities () {
      this.saveApprovedCitiesLoading = true
      const data = this.citiesToAprove
      const url = 'admin/users/change-aprroved-status'

      await this.$http.post(url, data)
      this.$emit('update')
      this.saveApprovedCitiesLoading = false
      this.modal = false
    },
    async approveMaterial (item, status) {
      let data = null
      let val = null
      if (status === 1) {
        val = await swal.fire({
          title: 'Confirma aprovação?',
          iconColor: 'green',
          allowOutsideClick: false,
          showCancelButton: true
        })
        data = {
          id: item.id,
          approve: 1
        }
      } else {
        val = await swal.fire({
          title: 'Confirma reprovação?',
          text: 'Informe o motivo da reprovação',
          iconColor: 'red',
          input: 'text',
          allowOutsideClick: false,
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Informe corretamente o motivo da reprovação'
            }
          }
        })
        if (val) {
          data = {
            id: item.id,
            approve: 0,
            message: val.value
          }
        }
      }
      if (val && val.isConfirmed) {
        const url = 'admin/users/change-aprroved-status'
        console.log('data', data)
        await this.$http.post(url, data)
        this.$emit('update')
      }
    },
    async cancelApprove (item) {
      item.cancelApprove = true
      const data = [{ ...item, approved: false }]
      const url = 'admin/users/change-aprroved-status'

      await this.$http.post(url, data)
      this.$emit('update')
      item.cancelApprove = false
    }
  },
  filters: {
    priceUnity ({ price, unity }) {
      const currency = Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(price)

      return `${currency}/${unity}`
    }
  }
}
</script>
<style scoped>
table {
  border-radius: 0.75rem;
  overflow: hidden;
}

table thead tr {
  border-top: 2px transparent solid;
}

.avatar-large-content {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 1px solid #ccc;
}

.avatar-large-content img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.icon-table{
    width: 30px !important;
    height: 30px !important;
    text-align: center !important;
    line-height: 30px !important;
    border-radius: 15px !important;
    margin: 0 10px !important;
}

.icon-table-green{
  background-color: green;
  color: #FFFFFF !important;
}

.icon-table-red{
  background-color: red;
  color: #FFFFFF !important;
}

.icon-table-info{
  background-color: blue;
  color: #FFFFFF !important;
}

</style>
